<template>
  <v-dialog v-model="modal.toggle" persistent width="30%">
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isEdit="modal.isEdit"
          :title="modal.isEdit ? costCenter.name : 'Centro de custo'"
          @onClose="closeModal()"
        />

        <v-radio-group v-model="isSubCostCenter">
          <v-radio
            v-for="item in [{ name: 'Centro de custo', isSubCostCenter: false }, { name: 'Subcentro de custo', isSubCostCenter: true }]"
            :key="item.name"
            :label="item.name"
            :value="item.isSubCostCenter">
          </v-radio>
        </v-radio-group>

        <v-text-field
          v-model="costCenter.name"
          label="Nome" outlined
          :error-messages="costCenterNameErrors"
          @blur="$v.costCenter.name.$touch()">
        </v-text-field>

        <v-autocomplete
          v-if="isSubCostCenter"
          v-model="costCenter.costCenterId"
          label="Centro de custo"
          append-icon="mdi-magnify"
          item-text="name"
          item-value="id"
          :items="costCentersMapped"
          :error-messages="costCentersMappedErrors"
          @blur="$v.costCenter.costCenterId.$touch()"
          clearable
          outlined
          filled
          dense>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <OrganizzeModalFooter @onConfirm="enviar" :loading="modal.loading"/>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { required, requiredIf } from 'vuelidate/lib/validators'
import validation from '../validation'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { isValidParam } from '@/utils/validationUtils'

const namespaceStore = 'organizzeCentroCustos'

export default {
  name: 'OrganizzeCentroCustosModalCadastro',
  props: {},
  directives: {},
  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },
  data: () => ({
    modal: {
      toggle: false,
      isEdit: false,
      title: 'centro de custo',
      loading: false
    },
    isSubCostCenter: false,
    costCenter: {
      id: null,
      name: '',
      costCenterId: null
    }
  }),
  created () {},
  mounted () {
    const self = this

    Events.$on('modalCadastro::open', () => {
      self.modal.toggle = true
    })

    Events.$on('modalCadastro::edit', data => {
      const self = this

      self.modal = {
        toggle: true,
        isEdit: true,
        title: data.costCenter.name,
        loading: false
      }

      self.isSubCostCenter = isValidParam(data.costCenter.costCenterId)

      self.costCenter = {
        id: data.costCenter.id,
        name: data.costCenter.name,
        costCenterId: data.costCenter.costCenterId
      }
    })
  },
  computed: {
    ...mapGetters(namespaceStore, ['costCentersMapped']),
    ...validation
  },
  watch: {
    isSubCostCenter (val) {
      if (val === false) {
        const self = this
        self.costCenter.costCenterId = null
      }
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['submit']),
    closeModal () {
      const self = this
      self.modal = {
        toggle: false,
        isEdit: false,
        title: 'centro de custo',
        loading: false
      }
      self.isSubCostCenter = false
      self.costCenter = {
        id: null,
        name: '',
        costCenterId: null
      }
    },

    enviar () {
      const self = this

      if (this.$v.$invalid) {
        errorSnackbar('Preencha os campos corretamente!')
        this.$v.$touch()
        return
      }

      self.modal.loading = true

      self.submit({ data: self.costCenter, isEdit: self.modal.isEdit}).then(() => {
        if (!self.modal.isEdit) successSnackbar('Centro de custo adicionado com sucesso.')
        else successSnackbar('Centro de custo editado com sucesso.')
        Events.$emit('index::getCostCenter')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
      })
    }
  },
  validations () {
    const self = this
    return {
      costCenter: {
        name: { required },
        costCenterId: { required: requiredIf(() => self.isSubCostCenter) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .toolBar {
    background-color: $colorPrimary !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: white;

    &-text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
</style>