import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  costCenterNameErrors () {
    const errors = []
    if (!this.$v.costCenter.name.$dirty) return errors
    !this.$v.costCenter.name.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  costCentersMappedErrors () {
    const errors = []
    if (!this.$v.costCenter.costCenterId.$dirty) return errors
    !this.$v.costCenter.costCenterId.required && errors.push(REQUIRED_FIELD)
    return errors
  }
}